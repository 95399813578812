/* Position and sizing of burger button */
.bm-burger-button {
	position: fixed;
	width: 36px;
	height: 30px;
	right: 20px;
	top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
	background: #000000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
	background: #ff9900;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
	height: 24px;
	width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
	background: #ffffff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
	position: fixed;
	height: 100%;
}

/* General sidebar styles */
.bm-menu {
	background: #333333;
	padding: 0;
	font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
	fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
	color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
	background: rgba(0, 0, 0, 0.3);
}

/* Individual item */
.bm-item {
	display: block;
	margin:0;
	padding:20px 0;
	text-align: left;
	list-style:none;
	transition: color 0.2s;
}
.bm-item li {
	margin:0;
	padding:0;
}
.bm-item li a {
	display: block;
	padding:4px 20px;
	color:#ffffff;
	text-decoration:none;
}
.bm-item li a:hover {
	color: #cccccc;
}